import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import './index.styl';


const NativeStyles = ({ theme }) => {
  const {
    animations: {
      hover: hoverAnimation,
    },
    fonts: fontsAtoms,
    colors: colorsAtoms,
  } = theme;

  return (
    <Fragment>
      <style global jsx>{`
        .native-ad.card-read-more
          .ad_native_block
            background ${colorsAtoms.content}

          .ad_native_content-wrapper
            background ${colorsAtoms.layout}
            border-bottom 2px solid ${colorsAtoms.layout}

          .ad_native_img
            &:after
              transition opacity ${hoverAnimation}
              background-color ${colorsAtoms.galleryInfo}

          .ad_native_title
            font-family ${fontsAtoms.text}
            color ${colorsAtoms.primary}
            transition color ${hoverAnimation}

          .ad_label__text
            color ${colorsAtoms.placeholder}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function CardReadMore(Component) {
  return withTheme(props => {
    return (
      <div className={cx(
        'native-ad',
        'card-read-more',
      )}
      >
        <NativeStyles {...props} />
        <Component {...props} />
      </div>
    );
  });
}
