import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import { getOffset } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';
import { renderError } from 'core/utils/app-status-helper';

import withBreakpoint from 'core/hocs/withBreakpoint';

import H1 from 'core/components/H1';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  VerticalIndent,
} from 'site/components/Indents';

import RubricLayout from 'site/components/RubricLayout';
import CardsList from 'site/components/CardsList';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card4 from 'site/cards/Card4';

import ListPage from 'core/components/ListPage';

const limit = 10;
const filterMap = {
  photo: { topic_type: 'gallery' },
  video: { tag: 'video' },
};


function MediaSectionPage(props) {
  const {
    match: {
      params: {
        level_1: level1,
      },
    },
    location,
    isDesktop,
  } = props;

  const topicTypeMap = {
    photo: {
      title: 'Фото',
      description: 'Фотографии автомобилей на Motor.ru',
    },
    video: {
      title: 'Видео',
      description: 'Автомобильное видео на Motor.ru',
    },
  };

  const { data: rawTopics, isLoading } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      limit,
      offset: getOffset(location.search, limit),
      include: filterRequiredParams([Card1, Card2, Card4], 'include'),
      fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
      with_filtered_count: 1,
      ...filterMap[level1],
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params).catch(renderError),
  });

  return (
    <RubricLayout>
      <H1>{topicTypeMap[level1].title}</H1>
      <ListPage
        title={`${topicTypeMap[level1].title} — все статьи и новости`}
        description={topicTypeMap[level1].description}
        rawData={rawTopics}
        limit={limit}
        isLoading={isLoading}
      >
        {({ content }) => isLoading
          ? <PageLoader />
          : (
            <>
              <VerticalIndent indent={isDesktop ? 23 : 10} />
              <Card1 type={5} content={content[0]} />
              <VerticalIndent indent={isDesktop ? 16 : 10} />
              <CardsList topics={content.slice(1)} />
              <VerticalIndent indent={isDesktop ? 23 : 10} />
            </>
          )
        }
      </ListPage>
    </RubricLayout>
  );
}

MediaSectionPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withBreakpoint(MediaSectionPage);
