import PropTypes from 'prop-types';

import H2 from 'core/components/H2';
import Drum from 'core/components/Drum';
import Image from 'core/components/Image';
import ThemeSwitch from 'core/components/ThemeSwitch';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';

import resolveRelationships from 'core/utils/relationships';
import relationshipsPropTypes, {
  imagePropTypes,
  readMorePropTypes,
} from 'core/utils/prop-types/relationships';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { Card2Type0Ml as TopicsCard } from 'site/cards/Card2';

import styles from './styles.styl';

import { DRUM_CARD_MAX_WIDTH, getUtm } from 'site/constants';

export const requiredPayloadImports = [
  'image',
  'read_more',
];

export const requiredPayloadFields = [
  'headline',
  'list_headline',
  'alternative_headline',
];

export const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

export const SelectionCard = TopicsCard;

const DRUM_HORIZONTAL_PADDING = 16;


function Selection({ content, theme, isMobile, breakpoint }) {
  if (!content?.selection || !content?.topics?.length) return null;

  const Card = withBindProps({
    utm: getUtm(breakpoint),
    imageWidth: isMobile ? 256 : 282,
  })(TopicsCard);

  const {
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.selection.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content.selection);

  return (
    <div className={styles.wrapper}>
      <style jsx>{`
        .${styles.drum}
          padding 0 ${isMobile ? 0 : DRUM_HORIZONTAL_PADDING}px 24px ${DRUM_HORIZONTAL_PADDING}px

        .${styles.headlines}
          font-family ${theme.fonts.display}
          color ${theme.colors.card}

        .${styles.altHeadline}
          font-family ${isMobile ? theme.fonts.text : theme.fonts.display}
      `}</style>

      <Image
        src={cover}
        previewSrc={previewCover}
        {...!isMobile && {
          aspectRatio: 1.2,
          maxWidth: 908,
        }}
      />
      <div className={styles.fader}>
        <div className={styles.headlines}>
          <ThemeSwitch name='dark'>
            <H2>{listHeadline || headline}</H2>
            <div className={styles.altHeadline}>{altHeadline}</div>
          </ThemeSwitch>
        </div>
        <div className={styles.drum}>
          <Drum
            content={content.topics}
            card={Card}
            spaceBetween={12}
            itemWidthMobile={DRUM_CARD_MAX_WIDTH}
            arrowsShift={DRUM_HORIZONTAL_PADDING + 10}
            noFade
            noCounter
          />
        </div>
      </div>
    </div>
  );
}

Selection.propTypes = {
  /** Контент подборки */
  content: PropTypes.shape({
    selection: relationshipsPropTypes({
      image: imagePropTypes(),
      read_more: readMorePropTypes(),
    }),
    topics: PropTypes.array,
  }),

  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
};

const SelectionWithHOCs = withTheme(withBreakpoint(Selection));
SelectionWithHOCs.displayName = 'Selection';

export default SelectionWithHOCs;

export { Selection as StorybookComponent };
