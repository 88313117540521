import GalleryWithAds from 'site/components/GalleryWithAds';
import RcmEventsSender from 'core/components/RcmEventsSender';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';


function TopicMediaGallery(props) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = props.content.relationships;

  return (
    <>
      {photoGallery && (
        <GalleryWithAds
          adsToReload={[
            {
              name: 'Billboard',
              count: 1,
            },
            {
              name: '240x400',
              count: 1,
            },
          ]}
          hashNavigation
          photoGallery={photoGallery}
          captionCreditsProps={{ position: 'left' }}
        />
      )}
      <RcmEventsSender pageread={{ itemId: props.content.id }} />
    </>
  );
}

TopicMediaGallery.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

export default TopicMediaGallery;
