import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import Topic from 'core/components/Topic';
import TopicTopline from 'core/components/TopicTopline';
import Link from 'core/components/Link';
import Comments from 'core/components/Comments';
import ColumnLayout from 'core/components/ColumnLayout';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import EmptyWrapper from 'core/components/EmptyWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import withBreakpoint from 'core/hocs/withBreakpoint';

import SideAdv from 'site/components/SideAdv';
import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';

import ArrowLeft from 'site/icons/ArrowLeft';

import TopicHeader from 'site/pages/topic/TopicHeader';
import TopicFooter from 'site/pages/topic/TopicFooter';
import TopicWrapper from 'site/pages/topic/TopicWrapper';

import {
  TopicContentIndent,
  VerticalIndent,
} from 'site/components/Indents';

import styles from './index.styl';


function CommentsPage(props) {
  const { isMobile } = props;
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <ViewportHeightWrapper>
      <TopicWrapper>
        <Topic content={data}>
          {topic => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
                is_premium: isPremium,
              },
            } = topic;

            const commentsProps = {
              xid: id,
              adv: true,
              advInterval: 4,
              isCommentsDisabled,
            };

            const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

            return (
              <Wrapper rightColumn={<SideAdv />}>
                <TopicContentIndent>
                  <VerticalIndent indent={20} />
                  {!isPremium && (
                    <>
                      <TopicTopline />
                      <VerticalIndent indent={isMobile ? 16 : 20} />
                      <TopicHeader content={topic} />
                      <VerticalIndent indent={25} />
                    </>
                  )}

                  <Link
                    to={link}
                    type='secondary'
                    className={styles.link}
                  >
                    <ButtonWithIcon
                      type='secondary'
                      icon={ArrowLeft}
                      size={isMobile ? 'medium' : 'large'}
                    >
                      Вернуться к статье
                    </ButtonWithIcon>
                  </Link>

                  <Comments {...commentsProps} />

                  {!isPremium && <TopicFooter />}
                </TopicContentIndent>
              </Wrapper>
            );
          }}
        </Topic>
      </TopicWrapper>
    </ViewportHeightWrapper>
  );
}

CommentsPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(CommentsPage);
