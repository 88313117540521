import PropTypes from 'prop-types';

import color from 'core/libs/color';
import withTheme from 'core/hocs/withTheme';

import Link from 'core/components/Link';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { doubleDashesReplacer, textReplacer } from 'site/utils';
import { REG_DOUBLE_DASHES, REG_TRIPLE_DASHES } from 'site/constants';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
  'topic_type',
];


function Card5(props) {
  const {
    content,
    theme,
    counterName,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      alternative_headline: altHeadline,
      topic_type: topicType,
    },
  } = content;

  const modifyHeadline = doubleDashesReplacer(listHeadline || headline);
  const modifyAltHeadline = textReplacer(altHeadline, [
    [REG_DOUBLE_DASHES, '$1&ndash;$2'],
    [REG_TRIPLE_DASHES, '&mdash;'],
  ]);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.primary}
          font ${theme.texts.authorSmall.font}
          border-bottom 1px solid ${color(theme.colors.primary).alpha(0.2).string()}
      `}</style>
    </scope>
  );

  return (
    <Link to={link} className={scoped.wrapClassNames(styles.link)}>
      <style jsx>{`
        .${styles.counter}
          &:before
            font ${theme.texts.h1.font}
            color ${theme.colors.placeholder}
            counter-increment ${counterName}
            content counter(${counterName})
      `}</style>
      {!!counterName && <div className={styles.counter} />}
      <div className={styles.text}>
        <MarkdownWrapper>
          {topicType === 'news'
            ? modifyHeadline
            : modifyAltHeadline || modifyHeadline
          }
        </MarkdownWrapper>
      </div>
      <scoped.styles />
    </Link>
  );
}

Card5.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /**
   * Указываем уникальное название счетчика
   */
  counterName: PropTypes.string,
};

const Card = withTheme(Card5);

Card.displayName = 'Card5';
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
