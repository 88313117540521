import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawContentQuery } from 'core/queries/searchPage';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withBreakpoint from 'core/hocs/withBreakpoint';

import SideAdv from 'site/components/SideAdv';

import {
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecPulseSearch,
} from 'site/components/Ads/mobile';

import Card3, { Card3Type4M, Card3Type5Xs } from 'site/cards/Card3';

import filters from './filters';

const Ad2Mobile = props => (
  <NegativeMobileSideIndent>
    <ListingSpecPulseSearch {...props} />
  </NegativeMobileSideIndent>
);


function SearchPage(props) {
  const {
    isMobile,
    location,
    history,
  } = props;

  const { data, isLoading } = useRequest(rawContentQuery({
    location,
    history,
    card: Card3,
  }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv withBouesque={false} />}>
        <CoreSearchPage
          isLoading={isLoading}
          rawContent={data}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={Ad2Mobile}
          card={isMobile ? Card3Type5Xs : Card3Type4M}
          interitemSpacing={isMobile ? 10 : 16}
        />
      </ColumnLayout>
    </PageIndent>
  );
}

SearchPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(SearchPage);
