import PopularCore from 'core/components/Popular';

import { filterRequiredParams } from 'core/utils/api';
import H2 from 'core/components/H2';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import withBindProps from 'core/hocs/withBindProps';

import Card5 from 'site/cards/Card5';

const include = filterRequiredParams([Card5], 'include');
const fields = filterRequiredParams([Card5], 'fields');

/**
 * TODO: в рамках задачи UCMS-2725 на время релиза, решено скрыть блок "Самое обсуждаемое"
 */
export default function Popular() {
  const COUNTER_NAME = 'popular-counter';

  return (
    <YandexMetrikaGoalSender
      click='samoe_chitaemoe_desktop_click'
      show='samoe_chitaemoe_desktop_show'
      checkScrollY
      ids={7378180}
    >
      <div className='countersBlock'>
        <style jsx>{`
          .countersBlock
            counter-reset ${COUNTER_NAME}
        `}</style>
        <PopularCore
          title='Самое читаемое'
          headerComponent={H2}
          card={withBindProps({ counterName: COUNTER_NAME })(Card5)}
          interitemSpacing={20}
          limit={5}
          sort='-views_count'
          include={include}
          fields={fields}
        />
      </div>
    </YandexMetrikaGoalSender>
  );
}
