import color from 'core/libs/color';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Link from 'core/components/Link';
import CommentsCount from 'core/components/CommentsCount';
import Lightning from 'core/components/GameCompare/Lightning';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      colors,
      fonts,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          background-color ${colors.card}

        .${styles.body}
          .${styles.card}:hover &
            background-color ${color(colors.active1).lighten(0.5).rgb().string()}

        .headline
          color ${colors.footer}
          font 700 24px/35px ${fonts.display}
          :global(.mobile) &
            font 700 18px/22px ${fonts.display}

        .${styles.announce}
          color ${colors.footer}
          font 300 18px/26px ${fonts.text}
          :global(.mobile) &
            font 300 14px/17px ${fonts.text}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={styles.wrapper}
      >
        <div className={styles.body}>
          <div className={styles.titles}>
            {(listHeadline || headline) && (
              <div className='headline'>
                {listHeadline || headline}
              </div>
            )}
            {firstAnnounce && (
              <div className={styles.announce}>
                {firstAnnounce}
              </div>
            )}
          </div>
          <div className={styles.comments}>
            <CommentsCount xid={content.id} />
          </div>
        </div>
        <Lightning
          imageMaxWidth={isMobile ? 546 : 392}
          height={265}
          widget={widgets[0]}
          progressType={1}
          progress={100}
          borderRadius='0 4px 4px 0'
          lightningColor={colors.card}
        />
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  content: modelPropTypes(topicAttributes),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

const Card = withTheme(withBreakpoint(CompareCard));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
