import PropTypes from 'prop-types';

import { Block, Section } from 'core/components/Grid';
import { Mobile, Desktop } from 'core/components/breakpoint';

import withTheme from 'core/hocs/withTheme';

import { SideIndent } from 'site/components/Indents';

function TopicWrapper({ theme, children }) {
  return (
    <div className='wrapper'>
      <style jsx>{`
        .wrapper
          background ${theme.colors.layout}
      `}</style>

      <Desktop>
        <Section>
          <SideIndent />
          <Block>
            {children}
          </Block>
          <SideIndent />
        </Section>
      </Desktop>
      <Mobile>
        {children}
      </Mobile>
    </div>
  );
}

TopicWrapper.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

export default withTheme(TopicWrapper);
