import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import AdWrapper from 'core/components/Ad/AdWrapper';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withBreakpoint from 'core/hocs/withBreakpoint';

import { VerticalIndent } from 'site/components/Indents';
import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';


function Authors(props) {
  const { isDesktop } = props;
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv />}>
        <H1>Авторы</H1>
        <VerticalIndent indent={30} />
        <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10} >
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

Authors.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Authors);
