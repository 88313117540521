import React from 'react';
import PropTypes from 'prop-types';

import { Block, Section } from 'core/components/Grid';

import withBreakpoint from 'core/hocs/withBreakpoint';

export const BLOCK_VERTICAL = 20;
export const BLOCK_MOBILE_VERTICAL = 20;
export const BLOCK_HORIZONTAL = 24;
export const BLOCK_MOBILE_HORIZONTAL = 10;

export const CONTENT_HORIZONTAL = 50;

export function SideIndent(props) {
  return (
    <Block
      width={`${BLOCK_MOBILE_HORIZONTAL}px`}
      desktop={`${BLOCK_HORIZONTAL}px`}
      {...props}
    />
  );
}

export const NegativeMobileSideIndent = withBreakpoint(({ children, isMobile }) => {
  if (isMobile) {
    return (
      <div style={{ margin: `0 -${BLOCK_MOBILE_HORIZONTAL}px` }}>
        {children}
      </div>
    );
  }
  return children;
});

export function InnerIndent(props) {
  return (
    <Block
      width={0}
      desktop={`${BLOCK_HORIZONTAL}px`}
      {...props}
    />
  );
}

export function ContentHorizontalIndent(props) {
  return (
    <Block
      width={0}
      desktop={`${CONTENT_HORIZONTAL}px`}
      {...props}
    />
  );
}

export function NegativeContentHorizontal({ children }) {
  return (
    <div style={{ margin: `0 -${CONTENT_HORIZONTAL}px` }}>
      {children}
    </div>
  );
}

export function TopicContentIndent({ children }) {
  return (
    <Section>
      <Block
        width={`${BLOCK_MOBILE_HORIZONTAL}px`}
        desktop={`${CONTENT_HORIZONTAL}px`}
      />
      <Block>
        {children}
      </Block>
      <Block
        width={`${BLOCK_MOBILE_HORIZONTAL}px`}
        desktop={`${CONTENT_HORIZONTAL}px`}
      />
    </Section>
  );
}

export function VerticalIndent({ indent }) {
  return <div style={{ marginTop: `${indent}px` }} />;
}

VerticalIndent.propTypes = {
  indent: PropTypes.number,
};

VerticalIndent.defaultProps = {
  indent: BLOCK_VERTICAL,
};

export const EarsIndents = withBreakpoint(EarsBlock);

function EarsBlock({ isMobile, children }) {
  if (isMobile) {
    return children;
  }

  return <NegativeContentHorizontal>{children}</NegativeContentHorizontal>;
}

EarsBlock.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};
