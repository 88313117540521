import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Link from 'core/components/Link';

import Image from 'core/components/Image';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import themePropTypes from 'core/utils/prop-types/theme';
import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import styles from './index.styl';

const defaultAvatar = {
  rel_url: require('site/static/images/user-avatar.png'),
  width: 128,
  height: 128,
};


function Authors(props) {
  const {
    authors,
    small,
    isMobile,
    isLink,
    theme: {
      texts: textsAtoms,
    },
  } = props;

  const sizeAtom = small ? 'authorSmall' : 'author';
  const authorsAtoms = textsAtoms[sizeAtom];

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .avatar
          margin-right ${isMobile || small ? '8px' : '12px'}
      `}</style>
    </scope>
  );

  const AuthorItemComponent = isLink ? Link : 'div';

  const authorsData = Array.isArray(authors) && authors.reduce((acc, { attributes }, i) => ({
    avatars: acc.avatars.concat(
      <AuthorItemComponent
        key={`avatar${i}`}
        className={scope.wrapClassNames('avatar', styles.avatar, styles.authorLink)}
        {...isLink && { to: `/authors/${attributes.slug}` }}
      >
        <Image
          src={attributes.avatar
            ? attributes.avatar.attributes.versions.original
            : defaultAvatar}
          width={isMobile ? 32 : 42}
          aspectRatio={1}
          alt={attributes.name}
        />
      </AuthorItemComponent>
    ),
    names: acc.names.concat([(
      <AuthorItemComponent
        key={`link${i}`}
        className={cx(styles.authorLink, 'authorLink')}
        {...isLink && { to: `/authors/${attributes.slug}` }}
      >
        {attributes.name}
      </AuthorItemComponent>
    ), i < authors.length - 1 && ', ']),
  }), { avatars: [], names: [] });

  return (
    <div className={cx(styles.authors)}>
      <style jsx>{`
        .${styles.names}
          margin-left ${isMobile || small ? '0' : '10px'}
          font ${authorsAtoms.font}
          color ${authorsAtoms.color}
      `}</style>
      <div className={styles.avatars}>
        {authorsData.avatars}
      </div>
      <div className={cx(styles.names, 'names')}>
        {authorsData.names}
      </div>
      <scope.styles />
    </div>
  );
}

Authors.propTypes = {
  /**
   * Делать ли автора ссылкой
   */
  isLink: PropTypes.bool,
  /** Массив данных авторов, соответствующих модели `personAttributes` */
  authors: PropTypes.arrayOf(modelPropTypes(personAttributes)).isRequired,
  /** Уменьшение отступов для компактного отображения */
  small: PropTypes.bool,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`
    {
      texts: {
        author: {
          font,
          color,
        },
        authorSmall: {
          font,
          color,
        },
      }
    },
  `),
};

const AuthorsWithHOCs = withBreakpoint(withTheme(Authors));
AuthorsWithHOCs.displayName = 'Authors';

export default AuthorsWithHOCs;

export { Authors as StorybookComponent };
