import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import types from 'site/cards/Card1/types';
import mapSizes from 'site/cards/sizes';

import styles from './index.styl';

const CARD_TYPE = 6;

const { [types[CARD_TYPE].size]: cardSize } = mapSizes;

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style jsx global>{`
      .adf-wrapper-main-native-1
        .ad_native_wrapper
          .ad_native_title
            font-size ${cardSize.headlineSize}
            color #fff

        .ad_label__text
          color ${theme.colors.active1}
          font-family ${theme.fonts.display}

        .adv_info__text
          color ${theme.colors.layout}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function MainNative1(Component) {
  return withTheme(props => (
    <div className={cx(styles['adf-wrapper-main-native-1'], 'adf-wrapper adf-wrapper-main-native-1')}>
      <NativeStyles theme={props.theme} />

      <Component {...props} />
    </div>
  ));
}
