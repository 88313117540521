import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Link from 'core/components/Link';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import themePropTypes from 'core/utils/prop-types/theme';

import {
  BLOCK_HORIZONTAL,
  CONTENT_HORIZONTAL,
} from 'site/components/Indents';

function TopicCredit({ caption, credits, creditsUrl, theme, isDesktop }) {
  if (!caption && !credits) {
    return null;
  }

  const {
    colors,
    texts: {
      coverCopyright: coverCopyrightAtoms,
    },
  } = theme;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
      .creditsUrl
        text-decoration none
        color inherit
        &:visited
        &:hover
        &:active
          text-decoration none
      `}</style>
    </scope>
  );

  return (
    <div className='wrapper'>
      <style jsx>{`
        .wrapper
          bottom 0
          left 0
          right 0
          text-align right
          box-sizing content-box
      `}</style>
      <style jsx>{`
        .wrapper
          position ${isDesktop ? 'absolute' : 'static'}
          padding ${isDesktop ? `28px ${BLOCK_HORIZONTAL}px 10px ${BLOCK_HORIZONTAL + CONTENT_HORIZONTAL}px` : 0}
          background linear-gradient(transparent 15%, ${colors.layout})
          color ${coverCopyrightAtoms.color}
          font ${coverCopyrightAtoms.font}
        .caption
        .credits
          display ${isDesktop ? 'inline' : 'block'}
      `}</style>
      <div className='info'>
        {caption && <div className='caption'>{caption}</div>}
        {credits && <div className='credits'>
          {creditsUrl ? (
            <Link
              className={scoped.wrapClassNames('creditsUrl')}
              to={creditsUrl}
              type='blank'
            >
              &copy; {credits}
            </Link>
          ) : <>&copy; {credits}</>}
        </div>}
      </div>
      <scoped.styles />
    </div>
  );
}

TopicCredit.propTypes = {
  caption: PropTypes.string,
  credits: PropTypes.string,
  creditsUrl: PropTypes.string,
  theme: themePropTypes(`
    {
      texts: {
        coverCopyright: {
          font,
          color,
        },
      },
      colors: {
        layout,
      }
    }
  `),
  isDesktop: PropTypes.bool,
};

export default withTheme(withBreakpoint(TopicCredit));
