import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import AdWrapper from 'core/components/Ad/AdWrapper';
import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import PageLoader from 'core/components/Loader/PageLoader';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { VerticalIndent } from 'site/components/Indents';

import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';

import { Card2Type1Ml } from 'site/cards/Card2';
import { Card4Type2 } from 'site/cards/Card4';

const breakpointCards = {
  [DESKTOP]: Card4Type2,
  [MOBILE]: Card2Type1Ml,
};


function Author(props) {
  const {
    isDesktop,
    breakpoint,
  } = props;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: 10,
      include: 'image,rubric,authors',
      card: breakpointCards[breakpoint],
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv />}>
        {authorIsLoading && <PageLoader />}
        {!authorIsLoading &&
          <AuthorPage
            author={author}
            topics={topics}
            card={breakpointCards[breakpoint]}
            columns={1}
            interitemSpacing={isDesktop ? 16 : 10}
            isLoading={topicsAreLoading}
          />
        }
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10} >
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

Author.propTypes = {
  isDesktop: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
};

export default withBreakpoint(Author);
