import PropTypes from 'prop-types';
import color from 'core/libs/color';

import withTheme from 'core/hocs/withTheme';
import styles from './index.styl';

function Rug({
  children,
  theme,
  radius,
  withoutBorder,
}) {
  const borderColor = color(theme.colors.primary).alpha(0.1).string();
  return (
    <div className={styles.rug}>
      <style jsx>{`
        .${styles.rug}
          border ${withoutBorder ? 'none' : `1px solid ${borderColor}` }
          border-radius ${radius}px
          background-color ${theme.colors.card}
          box-shadow 0 1px 2px 0 ${borderColor}
      `}</style>
      {children}
    </div>
  );
}

Rug.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Радиус скругления углов */
  radius: PropTypes.number,
  /** Флаг для отключения границы */
  withoutBorder: PropTypes.bool,
};

Rug.defaultProps = {
  radius: 2,
};

const RugWithHOCs = withTheme(Rug);
RugWithHOCs.displayName = 'Rug';

export default RugWithHOCs;

export { Rug as StorybookComponent };
