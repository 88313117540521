import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Mobile, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/hocs/withTheme';
import withTopic from 'core/hocs/withTopic';
import TopicFooter from 'core/components/TopicFooter';
import { Indent } from 'core/components/Wrappers';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';
import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import { Content4 } from 'site/components/Ads/mobile';

import {
  SuperFooter,
} from 'site/components/Ads/desktop';

import TopicFooterBottom from './TopicFooterBottom';


/**
 * Компонент актуален для страниц: article, news, media, comments.
 */
function TopicFooterWrapper(props) {
  const {
    topic,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
    isPremiumTopic,
  } = props;

  const isScrollToSite = checkScrollToSiteInTopic(topic);

  return (
    <Fragment>
      {!isPremiumTopic && (
        <Indent bottom={spacing}>
          <TopicFooter
            content={topic.content}
            hrefBuilder={(slug) => (
              `/label/${slug}`
            )}
          />
        </Indent>
      )}
      {(!isScrollToSite && !isPremiumTopic) && (
        <>
          <Desktop>
            <AdWrapper bottom={spacing}>
              <SuperFooter />
            </AdWrapper>
          </Desktop>
          <Mobile>
            <NegativeMobileSideIndent>
              <AdWrapper bottom={spacing}>
                <Content4 />
              </AdWrapper>
            </NegativeMobileSideIndent>
          </Mobile>
          <TopicFooterBottom />
        </>
      )}
      {isPremiumTopic && <TopicFooterBottom isPremiumTopic />}
    </Fragment>
  );
}

TopicFooterWrapper.propTypes = {
  topic: PropTypes.object,
  theme: PropTypes.object,
  isPremiumTopic: PropTypes.bool,
};

export default withTheme(withTopic(TopicFooterWrapper));
