import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import { Mobile, Desktop } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withReplaceCardWithAd from 'core/hocs/withReplaceCardWithAd';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { Card2Type1Ml } from 'site/cards/Card2';
import { Card3Type4M, Card3Type5Xs } from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import { cardReplacements } from 'site/utils';

import {
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import {
  SuperFooter,
  Native1AsCard4,
  Native2AsCard4,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';


function CardsList({ topics, isDesktop }) {
  const replacementCard = isDesktop ? Card3Type4M : Card3Type5Xs;
  const first = topics.slice(0, 3);
  const second = topics.slice(3);

  return (
    <Fragment>
      <Feed
        content={first}
        interitemSpacing={isDesktop ? 16 : 10}
        card={isDesktop ? Card4 : Card2Type1Ml}
        cardsReplacement={{
          ...cardReplacements(first, replacementCard),
          1: withReplaceCardWithAd(Native1AsCard4, isDesktop),
        }}
      />
      <VerticalIndent indent={isDesktop ? 16 : 10} />
      <Desktop>
        <SuperFooter />
        <VerticalIndent indent={16} />
      </Desktop>
      <Mobile>
        <NegativeMobileSideIndent>
          <Listing1 />
        </NegativeMobileSideIndent>
        <VerticalIndent indent={10} />
      </Mobile>
      <Feed
        content={second}
        interitemSpacing={isDesktop ? 16 : 10}
        card={isDesktop ? Card4 : Card2Type1Ml}
        cardsReplacement={{
          ...cardReplacements(second, replacementCard),
          [second.length - 2]: withReplaceCardWithAd(Native2AsCard4, isDesktop),
        }}
      />
      <Mobile>
        <VerticalIndent indent={10} />
        <ListingSpec />
      </Mobile>
    </Fragment>

  );
}

CardsList.propTypes = {
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** Массив топиков для формирования карточек */
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
};

const CardsListWithHOCs = withBreakpoint(CardsList);
CardsListWithHOCs.displayName = 'CardsList';

export default CardsListWithHOCs;

export { CardsList as StorybookComponent };
