import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import CommentsCount from 'core/components/CommentsCount';
import Socializator from 'core/components/Socializator';
import Link from 'core/components/Link';

import ButtonWithIcon from 'core/components/ButtonWithIcon';

import styles from './index.styl';

function Share(props) {
  const {
    isMobile,
    xid,
    hideDiscussBtn,
    topicLink,
    isCommentsDisabled,
  } = props;

  return (
    <div className={styles.wrapper}>
      {!hideDiscussBtn && !isCommentsDisabled && (
        <div className={styles.btn}>
          <Link type='secondary' to={`${topicLink}/comments`}>
            <ButtonWithIcon
              icon={() => <span style={{ marginLeft: '10px' }}><CommentsCount xid={xid} /></span>}
              type='secondary'
              iconPosition='right'
              minWidth={isMobile ? '100%' : 'auto'}
            >
              Обсудить
            </ButtonWithIcon>
          </Link>
        </div>
      )}
      <Socializator />
    </div>
  );
}

Share.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** id топика */
  xid: PropTypes.string.isRequired,
  /** Флаг, позволяющий скрыть кнопку `Обсудить` */
  hideDiscussBtn: PropTypes.bool,
  /** Ссылка на топик */
  topicLink: PropTypes.string,
  /** Признак отключенных комментариев */
  isCommentsDisabled: PropTypes.bool,
};

const ShareWithHOCs = withBreakpoint(Share);
ShareWithHOCs.displayName = 'Share';

export default ShareWithHOCs;

export { Share as StorybookComponent };
