import Video from 'core/components/Video';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(['video'], {}, { video: {} });


function TopicMediaVideo(props) {
  const {
    video: {
      code, caption, provider,
    },
  } = relationships(props.content);

  return (
    <Video
      code={code}
      caption={caption}
      providerName={provider}
    />
  );
}

TopicMediaVideo.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

export default TopicMediaVideo;
