import React from 'react';
import PropTypes from 'prop-types';

import H1 from 'core/components/H1';
import Link from 'core/components/Link';

import withBindProps from 'core/hocs/withBindProps';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

const H1asDIV = withBindProps({ is: 'div' })(H1);

const TitleWithLink = (props) => {
  const {
    to,
    headerComponent: Header,
    title,
    theme,
    isDesktop,
  } = props;

  if (isDesktop) return <Link to={to} type='secondary'><Header>{title}</Header></Link>;

  return (
    <Link to={to} type='secondary'>
      <style jsx>{`
        .${styles.link}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
      `}</style>
      <div className={styles.block}>
        <div className={styles.title}>
          <Header>{title}</Header>
        </div>
        <div className={styles.link}>Все</div>
      </div>
    </Link>
  );
};

TitleWithLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  headerComponent: PropTypes.func,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

TitleWithLink.defaultProps = {
  headerComponent: H1asDIV,
};

export default withTheme(withBreakpoint(TitleWithLink));
