import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import './global.styl';

const Global = ({ theme }) => {
  return (
    <Fragment>
      <style jsx global>{`
        .adf-wrapper
          .ad_native_img
            background-color ${theme.colors.primary}
            &:after
              transition opacity ${theme.animations.hover}

          .ad_native_wrapper
            > a
              font-family ${theme.fonts.display}
              text-decoration ${theme.controls.link.secondary.idle.decoration}

          .ad_native_title
            color ${theme.colors.primary}
      `}</style>
    </Fragment>
  );
};

Global.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Global);
