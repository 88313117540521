import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile, Desktop } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import withBreakpoint from 'core/hocs/withBreakpoint';
import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';

import { Indent } from 'core/components/Wrappers';

import CompareCard from 'site/cards/CompareCard';


function RubricTopics({ topics, isDesktop }) {
  if (!topics || !topics.length) return null;

  const first = topics.slice(0, 3);
  const second = topics.slice(3);

  const spacing = isDesktop ? 30 : 20;

  return (
    <AdWrapper top={spacing}>
      {first.length > 0 && (
        <Indent bottom={spacing}>
          <Feed
            interitemSpacing={spacing}
            card={CompareCard}
            content={first}
          />
        </Indent>
      )}

      <Desktop>
        <SuperFooter />
        <Indent bottom={spacing} />
      </Desktop>
      <Mobile>
        <Listing1 />
        <Indent bottom={spacing} />
      </Mobile>

      {second.length > 0 && (
        <Indent bottom={spacing}>
          <Feed
            interitemSpacing={spacing}
            card={CompareCard}
            content={second}
          />
        </Indent>
      )}
      <Indent bottom={spacing} />
    </AdWrapper>
  );
}

RubricTopics.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(RubricTopics);
