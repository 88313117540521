import PropTypes from 'prop-types';
import cx from 'classnames';

import { withRouter } from 'core/libs/router';
import color from 'core/libs/color';
import withBindProps from 'core/hocs/withBindProps';
import withCard from 'core/hocs/withCard';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import AdvTooltip from 'core/components/AdvTooltip';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Rug from 'site/components/Rug';

import mapTypes from './types';
import mapSizes from '../sizes';

import {
  imageVersionExists,
  doubleDashesReplacer,
  textReplacer,
} from 'site/utils';

import {
  MOBILE_MAX_WIDTH,
  REG_DOUBLE_DASHES,
  REG_TRIPLE_DASHES,
} from 'site/constants';

import IconPhoto from 'site/icons/Photo';
import IconVideo from 'site/icons/Video';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'list_headline',
  'ad_label',
  'advertiser_name',
  'link_with_announce_erid',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});

const iconsMap = {
  video: IconVideo,
  card: IconPhoto,
};


function Card2(props) {
  const {
    content,
    imageWidth,
    isMobile,
    theme,
    type,
    utm,
    size,
    location,
    position,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    topic_type: topicType,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const modifyHeadline = doubleDashesReplacer(listHeadline || headline, !isMobile && '<br />');
  const modifyAltHeadline = textReplacer(altHeadline, [
    [REG_DOUBLE_DASHES, '$1&ndash;$2'],
    [REG_TRIPLE_DASHES, '&mdash;'],
  ]);

  const {
    image: {
      versions: {
        ['list_3/2']: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    showAltHeadline,
  } = mapTypes[type] || {};

  const {
    iconSize,
  } = mapSizes[size] || {};

  const isMainPage = location.pathname === '/';
  const shouldShowAdvTooltip = !!(isMainPage && position === 0 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;
  const Icon = iconsMap[topicType] || (() => null);
  const to = utm ? topicLink + utm : topicLink;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headlines
          transition\
            background-color ${theme.animations.hover},\
            text-decoration-color ${theme.animations.hover}
          color ${theme.colors.primary}

          .${styles.link}:hover &
            background-color ${color(theme.colors.active1).lighten(0.5).rgb().string()}
      `}</style>
    </scope>
  );

  return (
    <Rug>
      <Link
        to={to}
        type='secondary'
        className={cx(styles.link, scoped.wrapClassNames('link'), styles[`_size_${size}`])}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        <style jsx>{`
          .${styles.headline}
            font-family ${theme.fonts.display}
            color ${theme.colors.dark}

          .${styles.altHeadline}
            font-family ${theme.fonts.text}
            color ${theme.colors.dark}

          .${styles.rubric}
            font-family ${theme.fonts.display}
            color ${theme.colors.active1}

          .${styles.overlay}
            transition opacity ${theme.animations.hover}
        `}</style>
        <div className={styles.overlayWrapper}>
          <SmartImage
            {...cover && { src: cover }}
            {...remoteImage && { url: remoteImage }}
            {...cover && { previewSrc: previewCover }}
            {...isMobile
              ? { maxWidth: imageWidth || MOBILE_MAX_WIDTH }
              : { maxWidth: imageWidth || 310 }}
            aspectRatio={1.5}
          />
          <div className={styles.overlay} />
          <div className={styles.overlayContent}>
            <Icon
              width={iconSize}
              height={iconSize}
              className={styles.icon}
            />
            {rubricRoot && (
              <div className={styles.rubric}>
                {rubricRoot}
              </div>
            )}
          </div>
        </div>
        <div className={cx(styles.headlines, (scoped.wrapClassNames('headlines')))}>
          <div className={styles.headline}>
            <MarkdownWrapper inline>{modifyHeadline}</MarkdownWrapper>
          </div>
          {showAltHeadline &&
            <div className={styles.altHeadline}>
              <MarkdownWrapper inline>{modifyAltHeadline}</MarkdownWrapper>
            </div>
          }
        </div>
      </Link>
      <scoped.styles />
      {shouldShowAdvTooltip && (
        <AdvTooltip text={advertiserName} adLabel={adLabel} />
      )}
    </Rug>
  );
}

Card2.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),

  /** Размер карточки */
  size: PropTypes.oneOf(['xs', 'm', 'ml']),

  /** Ширина изображения */
  imageWidth: PropTypes.number,

  /** @ignore */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,

  /** Строка с utm-метками, добавляемыми в url */
  utm: PropTypes.string,

  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),

  position: PropTypes.number,
};

Card2.defaultProps = {
  type: 0,
  size: 'm',
};

const Card = withCard(withRouter(Card2));

Card.displayName = 'Card2';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as Card2Type0M };
export const Card2Type0Xs = withBindProps({ type: 0, size: 'xs' })(Card);
export const Card2Type1Ml = withBindProps({ type: 1, size: 'ml' })(Card);
export const Card2Type0Ml = withBindProps({ type: 0, size: 'ml' })(Card);
export const Card2Type1M = withBindProps({ type: 1, size: 'm' })(Card);

export default Card;

export { Card2 as StorybookComponent };
