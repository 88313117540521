import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import color from 'core/libs/color';
import withTheme from 'core/hocs/withTheme';

import Rug from 'site/components/Rug';

import types from 'site/cards/Card3/types';
import mapSizes from 'site/cards/sizes';

import styles from './index.styl';

const CARD_TYPE = 0;
const IMAGE_RATIO = 3 / 2;
const size = 'xs';

const cardSize = mapSizes[size];

const NativeStyles = ({ theme }) => {
  const imageHeight = (types[CARD_TYPE].height || 100);

  return (
    <Fragment>
      <style jsx global>{`
        .adf-wrapper-main-native-2

          .ad_native_wrapper
            > a
              padding ${cardSize.contentPadding}
              transition background-color ${theme.animations.hover}

              &:hover
                background-color ${color(theme.colors.active1).lighten(0.5).rgb().string()}

          .ad_native_title
            font-family ${theme.fonts.text}
            font-size ${cardSize.headlineSize}

          .ad_label__text
            font-family ${theme.fonts.display}
            color ${theme.colors.placeholder}

          .ad_native_img
            margin ${cardSize.contentPadding}
            height ${imageHeight}px
            width ${Math.floor(imageHeight * IMAGE_RATIO)}px
            img
              background-color ${theme.colors.active1}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function MainNative2(Component) {
  return withTheme(props => (
    <div className={cx('adf-wrapper adf-wrapper-main-native-2', styles['adf-wrapper-main-native-2'])}>
      <Rug>
        <NativeStyles theme={props.theme} />

        <Component {...props} />
      </Rug>
    </div>
  ));
}
