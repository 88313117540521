import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';

import useRequest from 'core/hooks/useRequest';

import withTheme from 'core/hocs/withTheme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import ArrTop from 'site/icons/ArrTop';
import ArrBottom from 'site/icons/ArrBottom';

import brandsQuery from 'site/queries/brands';

import Skeleton from './Skeleton';

import brandsList from './brands.json';
import styles from './index.styl';


function Brands(props) {
  const {
    theme,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const { data, isLoading } = useRequest(brandsQuery());

  if (isLoading) return <Skeleton />;

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!data?.length) return null;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          transition border ${theme.animations.hover}
          &:hover
            border solid 1px ${color(theme.colors.primary).alpha(0.1).string()}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(styles.brands, isCollapsed && styles._isCollapsed)}>
      <div className={styles.wrapper}>
        {data.map(brand => {
          const {
            slug,
            name,
          } = brand.attributes;
          const localBrand = brandsList.find(el => el.slug === slug);
          const correctedSlug = localBrand?.motor_slug || slug;
          const brandUrl = `/theme/${correctedSlug}`;

          return (
            <Link type='secondary'
              key={correctedSlug}
              to={brandUrl}
              aria-label={name}
              className={scoped.wrapClassNames(styles.link)}
            >
              <QutoBrandIcon
                className={styles.icon}
                size={40}
                brand={brand}
              />
            </Link>
          );
        })}
      </div>

      <Button
        className={styles.button}
        minWidth={0}
        onClick={toggleCollapsed}
        aria-label={isCollapsed ? 'Свернуть список' : 'Раскрыть список'}
      >
        {isCollapsed
          ? <ArrTop className={styles.arrow} />
          : <ArrBottom className={styles.arrow} />
        }
      </Button>
      <scoped.styles />
    </div>
  );
}

Brands.propTypes = {
  theme: PropTypes.object,
};

const BrandsWithHOCs = withTheme(Brands);
BrandsWithHOCs.displayName = 'Brands';

export default BrandsWithHOCs;

export { Brands as StorybookComponent };
