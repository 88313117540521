import PropTypes from 'prop-types';

import { Block } from 'core/components/Grid';
import Image from 'core/components/Image';

import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import TopicCredit from 'site/pages/topic/TopicCredit';
import {
  BLOCK_HORIZONTAL,
} from 'site/components/Indents';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import styles from './index.styl';

const relationships = resolveRelationships(
  ['image', 'content'],
  {},
  {
    image: { versions: {} },
    content: { widgets: [] },
  },
);

function TopicCoverDesktop({
  content,
  maxWidth,
  maxHeight,
  hasHorizontalIndents,
}) {
  const {
    image: {
      versions,
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  const { headline } = content.attributes;
  const altAndTitle = caption || headline;

  return (
    <Block mobile={0}>
      <div className='wrapper'>
        <style jsx>{`
          .wrapper
            margin 0 -${hasHorizontalIndents ? BLOCK_HORIZONTAL : 0}px
        `}</style>
        <div className={styles.cover}>
          <Image
            versions={versions}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            placement='contain'
            useSchema
            alt={altAndTitle}
            title={altAndTitle}
          />
          <TopicCredit
            caption={caption}
            credits={credits}
            creditsUrl={creditsUrl}
          />
        </div>
      </div>
    </Block>
  );
}

TopicCoverDesktop.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  hasHorizontalIndents: PropTypes.bool,
};

export default withThemeSwitcher('dark')(TopicCoverDesktop);
