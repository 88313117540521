import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Image from 'core/components/SmartImage';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import { Desktop } from 'core/components/breakpoint';
import withCard from 'core/hocs/withCard';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function CardReadMore({ content, theme }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const {
    animations: {
      hover: hoverAnimation,
    },
    fonts: fontsAtoms,
    colors: colorsAtoms,
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${colorsAtoms.primary}
          background ${colorsAtoms.layout}
          transition color ${hoverAnimation}

          &:hover
            color ${colorsAtoms.active1}
      `}</style>
    </scope>
  );

  return (
    <Link
      type='secondary'
      to={link}
      className={scope.wrapClassNames(styles.link)}
    >
      <style jsx>{`
        .${styles.headline}
          font-family ${fontsAtoms.display}
          color ${colorsAtoms.primary}
        .${styles.overlay}
          transition opacity ${hoverAnimation}
      `}</style>
      <div className={styles.headline}>{listHeadline || headline}</div>
      {cover && (
        <Desktop>
          <div className={styles.image}>
            <Image
              src={cover}
              width={107}
              aspectRatio={1}
            />
            <div className={styles.overlay} />
          </div>
        </Desktop>
      )}
      <scope.styles />
    </Link>
  );
}

CardReadMore.propTypes = {
  /** Контент топика */
  content: PropTypes.object,

  /** @ignore */
  theme: PropTypes.object,
};

const Card = withCard(CardReadMore);

Card.displayName = 'CardReadMore';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardReadMore as StorybookComponent };
