import { compose } from 'core/libs/recompose';

import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';
import Adfox from 'core/components/Ad/Adfox';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import cardReadMore from './styles/CardReadMore';
import listingSpecNativeStyles from './styles/LisitngSpecNative';
/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '459770140',
  height: 250,
};
const listingSpecProps = withBindProps({
  ...commonProps,
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '462240324',
  options: {
    p1: 'bxymd',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = withBindProps({
  ...commonProps,
  name: 'TopBanner',
  blockId: '460313267',
  options: {
    p1: 'bvqmo',
    p2: 'fexc',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = withBindProps({
  ...commonProps,
  name: 'Fullscreen',
  height: null,
  blockId: '460313271',
  options: {
    p1: 'bvqms',
    p2: 'etsa',
    pct: 'a',
  },
})(Ad);

export const Rich = withBindProps({
  ...commonProps,
  name: 'Rich',
  height: null,
  blockId: '460313273',
  options: {
    p1: 'bvqmt',
    p2: 'ffhi',
    pct: 'a',
  },
})(Ad);

export const Listing1 = withBindProps({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '460313247',
  options: {
    p1: 'bwttn',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing2 = withBindProps({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '460313249',
  options: {
    p1: 'bwttg',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing3 = withBindProps({
  ...commonProps,
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '460313251',
  options: {
    p1: 'bwttn',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Listing4 = withBindProps({
  ...commonProps,
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '460313253',
  options: {
    p1: 'bwttm',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ListingSpec = compose(
  listingSpecProps,
  listingSpecNativeStyles
)(Ad);

export const ListingSpecPulseSearch = compose(
  listingSpecProps,
  cardReadMore,
)(Ad);

export const Footer = withBindProps({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '460313263',
  options: {
    p1: 'bxbbx',
    p2: 'fexd',
    pct: 'a',
  },
})(Ad);

export const Sponsored = compose(
  withBindProps({
    ...commonProps,
    name: 'Sponsored (COM)',
    height: null,
    blockId: '460313265',
    options: {
      p1: 'bwujb',
      p2: 'fooq',
      pct: 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const InPage = withBindProps({
  ...commonProps,
  name: 'InPage',
  height: 200,
  blockId: '460313269',
  options: {
    p1: 'bvqmr',
    p2: 'fexd',
    pct: 'a',
  },
})(Ad);

export const Content1 = withBindProps({
  ...commonProps,
  name: 'Content1',
  lazy: true,
  blockId: '460313255',
  options: {
    p1: 'bwtth',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content2 = withBindProps({
  ...commonProps,
  name: 'Content2',
  lazy: true,
  blockId: '460313257',
  options: {
    p1: 'bwttj',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content3 = withBindProps({
  ...commonProps,
  name: 'Content3',
  lazy: true,
  blockId: '460313259',
  options: {
    p1: 'bwttk',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content4 = withBindProps({
  ...commonProps,
  name: 'Content4',
  lazy: true,
  blockId: '460313261',
  options: {
    p1: 'bwttl',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const ContentSpec = compose(
  withBindProps({
    ...commonProps,
    name: 'ContentSpec',
    lazy: true,
    blockId: '462240332',
    options: {
      p1: 'bxyme',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  cardReadMore,
)(Ad);

export const InGallery = withBindProps({
  ...commonProps,
  name: 'InGallery',
  blockId: '579389974',
  disableAutoInit: true,
  height: null,
})(Ad);

export const PopUp = withBindProps({
  ...commonProps,
  name: 'PopUp',
  height: null,
  blockId: '579502877',
  options: {
    siteId: '459770140',
  },
})(Ad);

export const TestAd = withBindProps({
  name: 'TestAd',
  ownerId: 171817,
  params: {
    p1: 'dfsap',
    p2: 'frfe',
  },
})(Adfox);
