import { useContext } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import ContentBackground from 'core/components/ContentBackground';
import Shapka, { StickyContainer } from 'core/components/Shapka';
import { Mobile, Desktop } from 'core/components/breakpoint';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { SideIndent } from 'core/components/Wrappers';
import StoryTopics from 'core/components/stories/StoryTopics';
import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import EmptyWrapper from 'core/components/EmptyWrapper';
import withTheme from 'core/hocs/withTheme';
import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import CookiesBanner from 'core/components/CookiesBanner';
import { isGameCompare } from 'core/components/GameCompare/utils';

import Scooter from '../Scooter';
import DarkWrapper from '../DarkWrapper';

import {
  Billboard,
  ShowUp,
  PopUp as DesktopPopUp,
  TestAd as TestAdDesktop,
} from '../Ads/desktop';
import {
  TopBanner,
  PopUp as MobilePopUp,
  TestAd as TestAdMobile,
} from '../Ads/mobile';
import { VerticalIndent } from '../Indents';

import Rug from 'site/components/Rug';
import Brands from 'site/components/Brands';
import brandsList from 'site/components/Brands/brands.json';

const DarkWrapperComponent = withThemeSwitcher('dark')(DarkWrapper);


function SiteLayout(props) {
  const {
    children,
    location: {
      pathname,
    },
    theme,
  } = props;

  const topic = useContext(GlobalTopicContext);

  const isPremiumTopic = topic?.attributes?.is_premium;
  const isMainPage = pathname === '/';
  const isGameComparePath = isGameCompare(pathname);
  const brandsFilter = brandsList
    .map(el => el.slug)
    .join(',');
  const Wrapper = isGameComparePath ? DarkWrapperComponent : EmptyWrapper;
  const { top, bottom } = theme.layout.indents;

  return (
    <StickyContainer>
      <Wrapper>
        <ContentBackground>
          <Layout>
            <Header>
              <Shapka
                isSecondShapkaForPremium={isPremiumTopic}
                fullwidth={isPremiumTopic}
                extra={(
                  <>
                    <Desktop>
                      <DesktopPopUp />
                    </Desktop>
                    <Mobile>
                      <MobilePopUp />
                    </Mobile>
                  </>
                )}
              />
            </Header>
            <Content>
              {!isPremiumTopic && (
                <>
                  <Desktop>
                    <Billboard />
                    <ShowUp />
                    <TestAdDesktop />
                  </Desktop>

                  <Mobile>
                    <TopBanner />
                    <TestAdMobile />
                  </Mobile>
                </>
              )}

              {isMainPage && (
                <Desktop>
                  <Rug radius={0}>
                    <VerticalIndent indent={8} />
                    <SideIndent>
                      <Brands brandsFilter={brandsFilter} />
                    </SideIndent>
                    <VerticalIndent indent={8} />
                  </Rug>
                </Desktop>
              )}

              {!isPremiumTopic && (
                <SideIndent>
                  <StoryTopics top={top} bottom={isMainPage ? -bottom : bottom}  />
                </SideIndent>
              )}
              {children}
            </Content>
            <Footer>
              <Scooter />
            </Footer>
            <CookiesBanner />
          </Layout>
        </ContentBackground>
      </Wrapper>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.object,
};

export default withRouter(withTheme(SiteLayout));
