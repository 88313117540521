import withBreakpoint from 'core/hocs/withBreakpoint';
import Exchange from 'core/components/Exchange';

export function PartnersVertical() {
  return (
    <Exchange
      pageType='desktop_light'
      blockType='vertical'
    />
  );
}

export function PartnersVerticalGameCompare() {
  return (
    <Exchange
      pageType='desktop_dark'
      blockType='vertical'
    />
  );
}

export const PartnersHorizontal = withBreakpoint(({ breakpoint }) => {
  return (
    <Exchange
      pageType={`${breakpoint}_topics`}
      blockType='horizontal'
    />
  );
});
